import React from 'react';

import withRoot from '../utils/withRoot';
import SEO from '../components/Seo';
import Page from '../components/Page';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: '100%',
    padding: 32,
    margin: '80px auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    display: 'block !important',
  },
});

function PrivacyPolicyPage(props) {
  const { classes } = props;

  return (
    <Page>
      <SEO title="MotiveBio - Privacy Policy" />

      <Grid container spacing={8} className={classes.root}>
        <Grid item xs={12}>
          <h1 className={classNames('typography-title mb-4', classes.title)}>
            Privacy Policy
          </h1>
          <p className="typography-p mb-2">
            We help women get a deeper understanding of their fertility health
            by tracking personal and lifestyle data and enabling convenient and
            affordable diagnostic testing of important fertility hormones. The
            data we collect will be used to prepare a report intended to help
            you take control of your healthcare journey to decide when and why
            you should see your doctor.
          </p>
          <p className="typography-p mb-4">
            Here we describe the privacy practices for our applications,
            software, websites, APIs, products, and services (the “Services”).
            You will learn about the data we collect, how we use it, the control
            we give you over your information, and the measures we take to keep
            it safe.
          </p>
        </Grid>
        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            1. Information we collect
          </h3>
          <p className="typography-p mb-4">
            When you use our Services, we collect the following types of
            information.
          </p>

          <h4 className="typography-subtitle mb-4">
            1.1 Information You Provide Us
          </h4>
          <h5 className="typography-subtitle-2 mb-4">
            1.1.1 Account Information
          </h5>
          <p className="typography-p mb-2">
            Some information is required to create an account on our Services,
            such as your name, email address, password, date of birth, gender,
            height, weight, race/ethnicity, and in some cases your mobile
            telephone number (for messaging if you elect to enable
            notifications). This is the only information you have to provide to
            create an account with us. Information that is optional is marked as
            such.
          </p>
          <p className="typography-p mb-4">
            Should new options be made available in the future, you may also
            choose to provide other types of information, such as a profile
            photo, biography, country information, and save physician and other
            information related to medical coverage for convenience purposes.
          </p>

          <h5 className="typography-subtitle-2 mb-4">
            1.1.2 Additional Information
          </h5>
          <p className="typography-p mb-2">
            To help improve your experience or enable certain features of the
            Services, you may choose to provide us with additional information,
            like your logs for female health (period) tracking, body
            measurements for lean and fat mass, fitness and lifestyle (i.e.,
            food/calorie intake, weight, sleep schedule, water intake, etc.); an
            alarm; and messages to or from your physician on the Services.
          </p>
          <p className="typography-p mb-2">
            You may also connect with your physician using the Services or
            invite your physician by providing their email address or using the
            contact list on your mobile device. We do not store these E-mails,
            or your contact list, and delete this information immediately after
            it is used.
          </p>
          <p className="typography-p mb-4">
            If you contact us for information or participate in helping us to
            gather feedback on features, surveys, contests, or promotions, we
            collect the information you submit such as your name, contact
            information, and message.
          </p>

          <h5 className="typography-subtitle-2 mb-4">
            1.1.3 Payment and Card Information
          </h5>
          <p className="typography-p mb-4">
            If you purchase MotiveBio whole blood sample collection kits on our
            website, you provide your payment information, including your name,
            credit or debit card number, card expiration date, CVV code, and
            billing address. We do not store this payment information. We store
            your shipping address to fulfill your order. Note that third-party
            payment processors may retain this information in accordance with
            their own privacy policies and terms.
          </p>

          <h4 className="typography-subtitle mb-4">
            1.2 Information We Receive From Your Use of Our Services
          </h4>
          <h5 className="typography-subtitle-2 mb-4">
            1.2.1 Location Information
          </h5>
          <p className="typography-p mb-4">
            The Services do not require the use of your location and we do not
            request this feature to be enabled on your device(s). However, your
            approximate location can be derived from your IP address.
          </p>

          <h5 className="typography-subtitle-2 mb-4">
            1.2.2 Usage Information
          </h5>
          <p className="typography-p mb-2">
            When you access or use our Services, we receive certain usage data.
            This includes information about your interaction with the Services,
            for example, when you view or search content, create or log into
            your account, input or edit data entries, or when you purchase a
            uniquely serialized collection kit which is linked directly to your
            account for the secure return of your personal clinical laboratory
            results.
          </p>
          <p className="typography-p mb-4">
            We also collect data about the devices and computers you use to
            access the Services, including IP addresses, browser type, language,
            operating system, or mobile device information (including device and
            application identifiers), the referring web page, pages visited,
            location (depending on the permissions you have granted us), and
            cookie information.
          </p>

          <h4 className="typography-subtitle mb-4">
            1.3 Health and Other Special Categories of Personal Data
          </h4>
          <p className="typography-p mb-4">
            To the extent that information we collect is health data or another
            special category of personal data subject to the European Union’s
            General Data Protection Regulation (“GDPR”), we ask for your
            explicit consent to process the data. We obtain this consent
            separately when you take actions leading to our obtaining the data,
            for example, when you pair your device to your account, grant us
            access to your exercise or activity data from another service, or
            use the female health tracking feature. You can use your account
            settings and tools to withdraw your consent at any time, including
            by stopping use of a feature, removing our access to a third-party
            service, unpairing your device, or deleting your data or your
            account.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            2. How We Use Information
          </h3>
          <p className="typography-p mb-4">
            We use the information we collect for the following purposes.
          </p>

          <h4 className="typography-subtitle mb-4">
            2.1 Provide and Maintain the Services
          </h4>

          <p className="typography-p mb-4">
            Using the information we collect, we are able to deliver the
            Services to you and honor our Terms of Service contract with you.
            For example, we need to use your information to provide you with
            your MotiveBio dashboard tracking your activity, period tracking
            logs, hormone testing results, and other health data and trends; to
            enable the community features of the Services; and to give you
            customer support.
          </p>

          <h4 className="typography-subtitle mb-4">
            2.2 Improve, Personalize, and Develop the Services
          </h4>

          <p className="typography-p mb-2">
            We use the information we collect to improve and personalize the
            Services and to develop new ones. For example, we use the
            information to troubleshoot and protect against errors; perform data
            analysis and testing; conduct research and surveys; and develop new
            features and Services.
          </p>
          <p className="typography-p mb-2">
            We also use your information to make inferences and show you more
            relevant content. For example:
          </p>
          <ul className="typography-p mb-4">
            <li>
              data that you provide in our proprietary interpretive algorithms
              can be used to predict the likelihood of certain health issues
              that you may wish to discuss with your healthcare provider.
            </li>
            <li>
              certain features may be recommended to you for the purpose of
              collecting additional information that will strengthen the
              detection algorithms.
            </li>
            <li>
              self-exams may be recommended to you to strengthen detection
              algorithms and can be shared with your healthcare provider.
            </li>
          </ul>

          <h4 className="typography-subtitle mb-4">2.3 Communicate with You</h4>

          <p className="typography-p mb-4">
            We use your information when needed to send you Service
            notifications and respond to you when you contact us. We also use
            your information to promote new features or products that we think
            would interest you. You can control marketing communications and
            most Service notifications by using your notification preferences in
            account settings or via the “Unsubscribe” link in an email.
          </p>

          <h4 className="typography-subtitle mb-4">
            2.4 Promote Safety and Security
          </h4>

          <p className="typography-p mb-2">
            We use the information we collect to promote the safety and security
            of the Services, our users, and other parties. For example, we may
            use the information to authenticate users, facilitate secure
            payments, protect against fraud and abuse, respond to a legal
            request or claim, conduct audits, and enforce our terms and
            policies.
          </p>
          <p className="typography-p mb-2">
            We use cookies and similar technologies for the purposes described
            above. For more information, please read our{' '}
            <a href="/cookie_policy">Cookie Use statement</a>.
          </p>
          <p className="typography-p mb-4">
            For personal data subject to the GDPR, we rely on several legal
            bases to process the data. These include when you have given your
            consent, which you may withdraw at any time using your account
            settings and other tools; when the processing is necessary to
            perform a contract with you, like the Terms of Service; and our
            legitimate business interests, such as in improving, personalizing,
            and developing the Services, marketing new features or products that
            may be of interest, and promoting safety and security as described
            above.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            3. How Information Is Shared
          </h3>
          <p className="typography-p mb-4">
            We do not share your personal information except in the limited
            circumstances described below.
          </p>

          <h4 className="typography-subtitle mb-4">
            3.1 When You Agree or Direct Us To Share
          </h4>

          <p className="typography-p mb-2">
            You may direct us to disclose your information to others, such as
            your physician.
          </p>
          <p className="typography-p mb-4">
            For certain information, we provide you with privacy preferences in
            account settings and other tools to control how your information is
            visible to other users of the Services.
          </p>

          <h4 className="typography-subtitle mb-4">
            3.2 For Legal Reasons or To Prevent Harm
          </h4>

          <p className="typography-p mb-2">
            We may preserve or disclose information about you to comply with a
            law, regulation, legal process, or governmental request; to assert
            legal rights or defend against legal claims; or to prevent, detect,
            or investigate illegal activity, fraud, abuse, violations of our
            terms, or threats to the security of the Services or the physical
            safety of any person.
          </p>
          <p className="typography-p mb-2">
            Please note: Our policy is to notify you of legal process seeking
            access to your information, such as search warrants, court orders,
            or subpoenas, unless we are prohibited by law from doing so. In
            cases where a court order specifies a non-disclosure period, we
            provide delayed notice after the expiration of the non-disclosure
            period. Exceptions to our notice policy include exigent or
            counterproductive circumstances, for example, when there is an
            emergency involving a danger of death or serious physical injury to
            a person.
          </p>
          <p className="typography-p mb-2">
            We may share non-personal information that is aggregated or
            de-identified so that it cannot reasonably be used to identify an
            individual. We may disclose such information publicly and to third
            parties, for example, in public reports about exercise and activity,
            to partners under agreement with us, or as part of the community
            benchmarking information we provide to users of our subscription
            services.
          </p>
          <p className="typography-p mb-2">
            If we are involved in a merger, acquisition, or sale of assets, we
            will continue to take measures to protect the confidentiality of
            personal information and give affected users notice before
            transferring any personal information to a new entity.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            4. Your Rights To Access and Control Your Personal Data
          </h3>
          <p className="typography-p mb-2">
            We give you account settings and tools to access and control your
            personal data, as described below, regardless of where you live. If
            you live in the European Economic Area, United Kingdom, and
            Switzerland (the “Designated Countries”), you have a number of legal
            rights with respect to your information, which your account settings
            and tools allow you to exercise, as outlined below.
          </p>

          <p className="typography-p mb-2">
            Accessing and Exporting Data. By logging into your account, you can
            access much of your personal information, including your dashboard
            and hormone test results. Using your account settings, you can also
            download information in a commonly used file format.
          </p>

          <p className="typography-p mb-2">
            Editing and Deleting Data. Your account settings let you change and
            delete your personal information. For instance, you can edit or
            delete the profile data you provide and delete your account if you
            wish.
          </p>

          <p className="typography-p mb-2">
            If you choose to delete your account, please note that while most of
            your information will be deleted within 30 days, it may take up to
            90 days to delete all of your information. This is due to the size
            and complexity of the systems we use to store data. We may also
            preserve data for legal reasons or to prevent harm, including as
            described in the How Information Is Shared section.
          </p>

          <p className="typography-p mb-2">
            Objecting to Data Use. We give you account settings and tools to
            control our data use. For example, using your notification settings,
            you can limit the notifications you receive from us.
          </p>

          <p className="typography-p mb-2">
            If you live in a Designated Country, in certain circumstances, you
            can object to our processing of your information based on our
            legitimate interests, including as described in the How We Use
            Information section. You have a general right to object to the use
            of your information for direct marketing purposes. Please see your
            notification settings to control our marketing communications to you
            about MotiveBio products and services. Please also review our{' '}
            <a href="/cookie_policy">Cookie Use statement</a> for your options
            to control how we and our partners use cookies and similar
            technologies for advertising.
          </p>

          <p className="typography-p mb-2">
            Restricting or Limiting Data Use. In addition to the various
            controls that we offer, if you reside in a Designated Country, you
            can seek to restrict our processing of your data in certain
            circumstances. Please note that you can always delete your account
            at any time.
          </p>

          <p className="typography-p mb-4">
            If you need further assistance regarding your rights, please contact
            our Information Security Officer at privacy@motivebio.com, and we
            will consider your request in accordance with applicable laws. If
            you reside in a Designated Country, you also have a right to lodge a
            complaint with your local data protection authority or with the Data
            Protection Commissioner, our lead supervisory authority.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            5. Data Retention
          </h3>
          <p className="typography-p mb-4">
            We keep your account information, like your name, email address, and
            password, for as long as your account is in existence because we
            need it to operate your account. In some cases, when you give us
            information for a feature of the Services, we delete the data after
            it is no longer needed for the feature. For instance, when you
            provide your contact list for finding a physician and to send your
            health information to your physician using the Services, we delete
            the list after it is used. We keep other information, until you use
            your account settings or tools to delete the data or your account
            because we use this data to provide you with your personal
            statistics and other aspects of the Services. We also keep
            information about you and your use of the Services for as long as
            necessary for our legitimate business interests, for legal reasons,
            and to prevent harm, including as described in the How We Use
            Information and How Information Is Shared sections.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            6. Analytics and Advertising Services Provided by Others
          </h3>
          <p className="typography-p mb-4">
            We work with partners who provide us with analytics services. This
            includes helping us understand how users interact with the Services.
            These companies may use cookies and similar technologies to collect
            information about your interactions with the Services and other
            websites and applications. To learn more and about your privacy
            choices, please read our{' '}
            <a href="/cookie_policy">Cookie Use statement</a>.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            7. Our International Operations and Data Transfers
          </h3>
          <p className="typography-p mb-2">
            We operate internationally and transfer information between the
            United States and other countries for the purposes described in this
            policy.
          </p>

          <p className="typography-p mb-2">
            We rely on multiple legal bases to lawfully transfer personal data
            around the world. These include your consent, the EU-US and Swiss-US
            Privacy Shield, and EU Commission approved model contractual
            clauses, which require certain privacy and security protections. You
            may obtain copies of the model contractual clauses by contacting us.
            Motive Biosciences Inc. complies with the Privacy Shield principles
            regarding the collection, use, sharing, and retention of personal
            information as described in our Privacy Shield certifications. Learn
            more about Privacy Shield here.
          </p>

          <p className="typography-p mb-2">
            Motive Biosciences Inc. is subject to the oversight of the US
            Federal Trade Commission and remains responsible for personal
            information that we transfer to others who process it on our behalf
            as described in the How Information Is Shared section. If you have a
            complaint about our Privacy Shield compliance, please contact us.
            You can also refer a complaint to our chosen independent dispute
            resolution body JAMS, and in certain circumstances, invoke the
            Privacy Shield arbitration process.
          </p>

          <p className="typography-p mb-4">
            Please note that the countries where we operate may have privacy and
            data protection laws that differ from, and are potentially less
            protective than, the laws of your country. You agree to this risk
            when you create a MotiveBio account and click “I agree” to data
            transfers, irrespective of which country you live in. For a list of
            the locations where we have offices, please see our company
            information here. If you later wish to withdraw your consent, you
            can delete your MotiveBio account as described in the Your Rights To
            Access and Control Your Personal Data section.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            8. Changes to This Policy
          </h3>
          <p className="typography-p mb-4">
            We will notify you before we make material changes to this policy
            and give you an opportunity to review the revised policy before
            deciding if you would like to continue to use the Services. Previous
            versions of the policy will be made available online in our archived
            documents.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            9. Who We Are and How To Contact Us
          </h3>
          <p className="typography-p mb-2">
            If you have questions, suggestions, or concerns about this policy,
            or about our use of your information, please contact us at
            info@motivebio.com.
          </p>

          <p className="typography-p mb-2">
            If you are seeking to exercise any of your statutory rights, please
            contact our Information Security Officer at privacy@motivebio.com.
          </p>
          <p className="typography-p mb-2">
            Motive Biosciences Inc., a U.S. company, is the data controller that
            provides you with the Services. You may contact us at:
          </p>
          <p className="typography-p mb-2">Motive Biosciences Inc.</p>
          <p className="typography-p mb-2">445 Medical Center Blvd.</p>

          <p className="typography-p mb-2">Webster, TX 77598</p>
          <p className="typography-p mb-2">privacy@motivebio.com</p>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(styles, { withTheme: true })(
  withRoot(PrivacyPolicyPage)
);
